html, body, #root {
  height: 100%;
}

body {
  padding: 20px;
}

section {
  margin: 10px;
}

table {
  border-spacing: 0;
  border: 1px solid black;
  width: 100%;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}

.pagination {
  padding: 0.5rem;
}

#cadastral {
  position: absolute;
  left: 10px;
  top: 10px;
}
